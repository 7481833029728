#sunburstContainer {
    background: #ffffff;
    margin-bottom: 10px;
}

#sunburstContainer svg {
    margin: auto;
    display: block;
}

.sunburst-container {
    background: white;
}

.sunburst-container #sunburstBreadCrumbs {
    padding: 15px;
    font-weight: 600;
}

.bread-crumb-button {
    border: none !important;
    color: #1890ff !important;
    cursor: pointer !important;
    padding: 0;
    background: transparent !important;
}

.display-inline-block {
    display: inline-block;
}

.hpo-loader {
    left: 0;
    right: 0;
    margin: auto;
    width: 100px;
    height: 100px;
}

.hpo-hierarchy-title {
    padding: 20px 20px 0 20px;
}

.bread-crumb-button::after {
    content: "->";
    padding: 0 10px;
    color: black;
}

button.bread-crumb-button:last-child::after {
    content: '';
}