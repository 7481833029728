.ReactTable .rt-tbody .rt-td {
    background: #fefefe !important;
}

.case-dashboard-option {
    margin-top: 4px;
    margin-bottom: 4px;
    overflow: hidden;
    font-size: 14px;
    text-overflow: ellipsis;
}

.case-dashboard-option i {
    margin-right: 10px;
}

.case-dashboard-option .ant-checkbox-group-item {
    display: block;
    padding: 10px;
}

.case-dashboard .ant-menu-submenu-title {
    padding: 0 !important;
}

.case-dashboard .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin: 0 !important;
}

.case-dashboard {
    background: #ffffff;
    z-index: 998;
    height: 100%;
}

.rt-table {
    background: #ffffff;
}

.dashboard-container .rt-thead.-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    box-shadow: none !important;
}

.ReactTable .rt-thead.-filters .rt-th {
    border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.case-dashboard-container {
    background: #f7f7f7;
    height: 100% !important;
    overflow: hidden;
}

.case-dashboard-container .dashboard-container {
    height: 100%;
    overflow: scroll;
}

.wrapper-container {
    background: #fff;
    padding: 20px;
    margin-bottom: 10px;
}

.genes-toggle-title {
    padding: 20px !important;
}

.case-dashboard-container .ant-menu-submenu-arrow {
    display: inherit !important;
}

.menu-separator {
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    width: 100%;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.separator {
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    width: 100%;
}

.case-dashboard {
    overflow-y: auto;
}

.case-dashboard .ant-menu-inline {
    border-right: transparent !important;
}

.slice {
    cursor: pointer;
}

.slice .main-arc {
    stroke: #fff;
    stroke-width: 1px;
}

.slice .hidden-arc {
    fill: none;
}

.slice text {
    pointer-events: none;
    dominant-baseline: central;
    text-anchor: middle;
}

.text-align-center {
    text-align: center;
}

.show-omim-details {
    color: #1890ff;
    background: transparent;
    border: none;
}

.case-dashboard-sub-component {
    padding: 10px !important;
    border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.case-dashboard-search-query {
    margin-top: 15px;
}

.case-dashboard-clear-search {
    margin-left: 10px;
}

.case-dashboard-clinical-features-search-input {
    margin: 10px;
}

.info-button {
    border: none !important;
    color: #1890ff !important;
}

.case-dashboard-link-text {
    border: none !important;
    color: #1890ff !important;
    cursor: pointer;
}

.verticle-margin-10 {
    margin: 10px;
}

.fragment-color-red {
    color: red !important;
    text-decoration: underline;
}

.fragment-color-blue {
    color: #1890ff !important;
    text-decoration: underline;
}

.case-dashboard-option label {
    display: block;
}

/* New Css */
.case-version-container {
    /* margin: 10px 0 20px 0 !important; */
}

.case-dashboard-clinical-feature-query div span, .case-dashboard-hpo-query div span {
    color: green;
    font-style: italic;
}

.case-dashboard-clinical-feature-query div span strong,
.case-dashboard-clinical-feature-query div span button span,
.case-dashboard-hpo-query div span strong,
.case-dashboard-hpo-query div span button span {
    color: black !important;
    font-style: normal !important;
}

.info-button {
    margin-left: 5px;
    font-size: 17px;
    color: rgb(0, 136, 204);
    margin-top: 2px;
}

.tableSearch {
    margin-top: 30px;
    margin-bottom: 10px;
}

span.mainHeading, div.mainHeading, .case-dashboard-option span.mainHeading {
    font-weight: 600 !important;
}

.ReactTable .rt-table .rt-thead .rt-tr .rt-th {
    font-weight: 600 !important;
    padding: 5px 3px;
}

.ReactTable .rt-table .rt-tbody .rt-tr-group .rt-tr .rt-td {
    font-size: 13px !important;
}

.ant-layout-header .ant-row .ant-col-19 .page-title {
    font-size: 15px !important;
}

.case-dashboard-container {
    margin: 30px 5px !important;
}

.case-dashboard {
    border-radius: 5px !important;
    border-top: 2px #006DF0 solid !important;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, .35);
}

.dashboard-container {
    padding: 0 10px !important;
    overflow: auto !important;
}

.sunburst-container {
    border-radius: 5px 5px 0 0 !important;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, .35);
    border-top: 2px #006DF0 solid !important;
    margin-bottom: 10px;
}

.case-dashboard-content-not-available {
    border-radius: 5px 5px 0 0 !important;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, .35);
    border-top: 2px #006DF0 solid !important;
    min-height: 200px;
    height: 100%;
    vertical-align: middle;
    overflow-x: hidden;
}

.case-dashboard-content-not-available .reload-content {
    margin: auto;
    top: 0;
    bottom: 0;
    position: absolute;
    width: 96%;
    height: 60px;
    text-align: center;
}

.wrapper-container {
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, .35);
    border-top: 2px #006DF0 solid !important;
    border-radius: 5px 5px 0 0 !important;
}

div.menu-separator.sixth-menu-separator {
    margin: 0 0 0 0
}

.case-dashboard-container .ant-col.ant-col-5.case-dashboard {
    border-right: 0 #006DF0 solid !important;
    height: 100% !important;
}

/* For Header Start */
header.ant-layout-header .page-title {
    color: #fff !important;
}

header.ant-layout-header {
    background: #fff !important;
    color: #000 !important;
    border-bottom: 2px #1890ff solid;
}

.ant-row.case-dashboard-container .case-dashboard {
}

/* For Header End */
.font-weight-600 {
    font-weight: 600 !important;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.display-table-cell {
    display: table-cell !important;
}

.margin-top-bottom-7 {
    margin: 7px 0;
}