/**
 * @author Punit Vajpeyi <punit@wemotiveforge.com>
 */
.application-logo-img-div {
    text-align: center;
}

.application-logo-img {
    width: 250px;
}

.survey-question-form-outer-div {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    margin: auto;
    position: absolute;
    background-image: url('../../../../assets/background.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

.survey-question-form-inner-div {
    width: 600px;
    padding: 20px;
    border: 1px solid #d9d9d9;
    margin: auto;
    background-color: rgba(255, 255, 255, 0.95);
}

.user-survey-form-button {
    width: 100%;
}

.postal-code::-webkit-inner-spin-button,
.postal-code::-webkit-outer-spin-button  {
    -webkit-appearance: none;
    margin: 0;
}