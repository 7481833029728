/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

.application-logo-img-div {
    text-align: center;
}

.application-logo-img {
    width: 250px;
}

.forgot-password-form-outer-div {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    margin: auto;
    position: absolute;
}

.forgot-password-form-inner-div {
    width: 450px;
    padding: 20px;
    border: 1px solid #d9d9d9;
    margin: auto;
}

.forgot-password-form-forgot {
    float: right;
}

.forgot-password-form-button {
    width: 100%;
}

/* New Css */

div.forgot-password-form-outer-div {
    background-image: url('../../../../assets/background.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

div.forgot-password-form-inner-div {
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 5px;
    width: 400px !important;
}

div.ant-row.ant-form-item {
    margin-bottom: 0 !important;
}

button.ant-btn.forgot-password-form-button.ant-btn-primary {
    margin-top: 15px;
}