.width-100 {
    width: 100%;
}

.case-version-container {
    margin: 10px 0;
}

.re-analyse-case {
    margin-left: 10px;
}