/**
 * @author Punit Vajpeyi <punit@wemotiveforge.com>
 */
.application-logo-img-div {
    text-align: center;
}

.application-logo-img {
    width: 250px;
}

.reset-password-form-outer-div {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    margin: auto;
    position: absolute;
    background-image: url('../../../../assets/background.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

.reset-password-form-inner-div {
    width: 450px;
    padding: 20px;
    border: 1px solid #d9d9d9;
    margin: auto;
    background-color: rgba(255, 255, 255, 0.95);
}

.force-reset-password-form-button {
    width: 100%;
}

.error-terms-condition-not-selected {
    color: #f5222d;
}