.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.-sort-asc {
    box-shadow: inset 0 3px 0 0 #1890ff !important;
}

/* New CSS */

body {
    /* margin: 0; */
    font-family: Nunito, sans-serif !important;
    /* font-size: 1.275rem; */
    /* font-weight: 400 !important; */
    /* line-height: 1.5; */
    /* color: #6c757d; */
    /* text-align: left; */
    background-color: #f5f6fa !important;
    height: 100% !important;
    background: #f5f6fa !important;
}

.page-content, .sivotec-page-content {
    background: #f5f6fa !important;
    background-color: #f5f6fa !important;
}