.purchase-plan {
    background-color: #00d593 !important;
    border: none !important;
    margin-top: 10px;
}

.disable-purchase-plan {
    background-color: #ffa500 !important;
    color: white !important;
    border: none !important;
}

.purchase-paypal-plan {
    margin-top: 10px;
}