.internal-phone-number-container .form-control {
    width: 100% !important;
}

.phone-error {
    color: #f5222d;
}

.profile-image {
    padding: 16px !important;
}