/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

.password-container {
    max-width: 450px;
    padding: 16px !important;
    background: #ffffff;
    margin: auto;
}

.reset-password-form-button {
    margin-right: 15px !important;
}