
.ant-layout {
    width: 100% !important;
    height: 100% !important;
    background: white;
}

.ant-layout-sider-children {
    background: white;
}

.page-content {
    background: white;
    margin-top: 50px !important;
    margin-bottom: 35px !important;
}

.ant-layout-footer {
    padding: 0 !important;
}

.save-case button {
    margin: 0 10px;
}

.clickable-span {
    color: #48A5FF;
}

.case-dashboard {
    padding: 10px !important;
}

.case-dashboard .ant-menu-item {
    padding-left: 16px !important;
}

.sivotec-page-content {
    padding: 16px !important;
    background: white;
}

.ReactTable .-previous .-btn {
    background: #1890ff !important;
    color: white !important;
}

.ReactTable .-previous .-btn:disabled {
    background: rgba(0, 0, 0, 0.1) !important;
    color: black !important;
}

.ReactTable .-next .-btn {
    background: #1890ff !important;
    color: white !important;
}

.ReactTable .-next .-btn:disabled {
    background: rgba(0, 0, 0, 0.1) !important;
    color: black !important;
}

.rt-td {
    white-space: normal !important
}

.ReactTable .rt-thead .rt-th.-sort-asc {
    box-shadow: none !important;
}

.ReactTable .rt-thead .rt-th.-sort-desc {
    box-shadow: none !important;
}

.ReactTable .rt-thead .rt-th.-sort-asc div:after {
    content: "\25bc";
    font-size: .7em;
    padding-left: 7px;
    vertical-align: middle;
    box-shadow: none !important;
}

.ReactTable .rt-thead .rt-th.-sort-desc div:after {
    content: "\25B2";
    font-size: .7em;
    padding-left: 7px;
    vertical-align: middle;
    box-shadow: none !important;
}
