.StripeElement {
    display: block;
    margin: 0;
    min-width: 200px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: 'Source Code Pro', monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
}

input::placeholder {
    color: #aab7c4;
}

input:focus,
.StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
    padding: 0;
}

.stripe-error {
    color: red;
}

.pay-button {
    margin-top: 20px;
    background: #1890FF;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 7px 10px;
}

.text-align-right {
    text-align: right;
}