div#planContainer {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 50px;
    overflow-y: hidden;
}

div.plan {
    margin: 3px;
    width: 200px;
    text-align: center;
    display: inline-block;
    height: 290px;
    background-color: #4219B9;
    vertical-align: top;
}

.plan-type-container {
    background: #f5f6fa !important;
}

.plan-type {
    background: #fbb400;
    color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 5px;
}

.plan-details {
    padding: 15px;
}

div.plan:first-child {
    background-color: #10B2BF;
}

div.plan:nth-child(2) {
    background-color: #187BA7;
}

div.plan:nth-child(3) {
    background-color: #084F6D;
}

div.plan:nth-child(4) {
    background-color: #4F839A;
}

div.plan:nth-child(5) {
    background-color: #652BA5;
}

div.plan:nth-child(6) {
    background-color: #A03494;
}

div.plan:nth-child(7) {
    background-color: #C2108F;
}

.current-plan {
    background-color: #00b455 !important;
}

.plan-type-bottom {
    background: transparent;
}

.color-white {
    color: white;
}

.plan-amount {
    font-size: 3em;
}

.align-top {
    vertical-align: top;
    line-height: 30px;
}

.subscription-amount-info {
    padding: 15px 0;
}

.subscription-actual-plan-amount {
    margin-top: -15px;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
}

.actual-plan-amount {
    text-decoration: line-through;
}

.color-gray {
    color: #ccc;
}

.arrow-down {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fbb400;
    margin-left: 90px;
}

.deactivate-plan {
    background: #fbb400 !important;
    margin-top: 10px;
    border: transparent !important;
}

.activate-plan {
    background: #00b455 !important;
    margin-top: 10px;
    border: transparent !important;
}
