.add-new-case {
    margin-bottom: 15px;
}

.text-align-center {
    text-align: center;
}

.check-analysis-status {
    color: #1890ff !important;
}

.case-table-case-name {
    color: #1890ff;
    cursor: pointer;
}

.view-case-button {
    color: #1890ff !important;
}

.case-danger {
    color: red !important;
}