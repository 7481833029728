.fixed-height-table .rt-tbody {
    max-height: 150px;
}

.fixed-height-table .pagination-bottom {
    display: none !important;
}

.margin-top-10 {
    margin-top: 10px !important;
}

.rt-thead.-header {
    border-bottom: solid 2px #e8e8e8;
    box-shadow: none !important;
}

.ReactTable .rt-tbody .rt-td {
    border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.ReactTable .rt-tbody .rt-tr-group {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.delete-segment {
    color: red !important;
}

.width-25 {
    width: 25px;
}