.remove-subscription-association {
    margin-left: 15px;
    color: red !important;
}

.subscription-tag {
    padding: 10px;
    margin: 5px 5px !important;
}

.margin-left-20 {
    margin-left: 20px;
}

.blank-placeholder-inline {
    display: inline-block;
    margin-left: 20px;
    width: 13px;
}