.hpo-suggestion-tag {
    background: #2db7f5;
    padding: 7px;
    border-radius: 5px;
    display: inline-block;
    margin: 5px;
}

.hpo-suggestion-tags div .hpo-suggestion-remove {
    vertical-align: middle;
    margin-left: 10px;
    color: white;
    display: none;
}

.hpo-suggestion-tags div:last-of-type .hpo-suggestion-remove {
    display: inline-block;
}

.hpo-operators button {
    margin: 10px;
}

.tags-container {
    padding: 10px;
}

.hpo-suggestion-suggestions {
    background: white;
    margin-top: 3px;
    list-style-type: none;
}

div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: .05em .01em .5em rgba(0, 0, 0, .2);
    background: white;
    padding-left: 0;
}

div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}

div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}

.case-dashboard-hpo-query {
    display: block;
    color: green;
    font-style: italic;
}

.case-dashboard-hpo-query strong {
    color: black !important;
    font-style: normal;
}

.search-hpo {
    border: none !important;
    margin-right: 10px;
    font-size: 17px;
    color: rgb(0, 136, 204);
    margin-top: 2px;
    display: inline-block;
    vertical-align: text-bottom;
}

.anticon-search.search-hpo {
    margin-left: 20px;
}

.hpo-search {
    width: 80% !important;
}

.hpo-error {
    color: red;
}

.HPOLookup span.title {
    padding-bottom: 2px !important;
}

.hpo-search-hierarchy {
    font-size: 11px;
}