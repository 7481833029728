.active-column-cell, .action-column-cell {
    text-align: center;
}

.ReactTable .rt-tbody .rt-td {
    background: #fefefe !important;
}

.green {
    color: green !important;
}

.red {
    color: red !important;
}

.sivotec-page-content {
    padding: 16px;
}

.user-enable {
    font-size: 20px;
    color: #006600 !important;
}

.user-disable {
    font-size: 20px;
    color: #FF6347 !important;
}