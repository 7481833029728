.sub-menu {
    right: 0 !important;
    top: 0 !important;
    position: absolute !important;
    color: white !important;
    text-align: center;
    width: 150px !important;
}

.sub-menu-icon {
    font-size: 1.5em !important;
    margin-top: 5px;
}

.sub-menu .ant-menu-submenu-title {
    text-align: right;
}

.ant-menu-submenu-arrow {
    display: none;
}

.ant-menu-item {
    text-align: left;
}

.logo {
    height: 50px;
    background: white;
    width: 168px;
    left: 0;
    position: absolute;
}

.ant-layout-header {
    padding: 0 !important;
}

.dashboard-application-logo-img {
    background: white;
    margin: 0 10px 15px 10px;
    height: 38px !important;
}

.logoTitle {
    font-size: 30px;
    color: black;
}

.page-title {
    color: white;
    margin-left: 20px;
    font-size: 18px;
}

.ant-layout-header {
    position: fixed;
    width: 100%;
    z-index: 999;
    background: #006DF0 !important;
    height: 50px !important;
    line-height: 50px !important;
}

.ant-menu-submenu-title {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.ant-menu-submenu-title:hover {
    color: #fff !important;
}

.headerLogoSection {
    text-align: center;
}

.headerLogoSection span.logoTitle .version {
    color: #64DD17;
}

.headerPageTitleSection {
    background-color: #1890ff !important;
}

.headerPageTitleSection::before {
    content: " " !important;
    margin-left: 15px !important;
}

.color-white {
    color: white !important;
}

.ant-menu.ant-menu-sub.ant-menu-vertical {
    position: fixed !important;
    right: 0;
    top: 48px !important;
}