.footer-list-inline {
    text-align: center;
    list-style: none;
    margin: 0;
    vertical-align: middle;
    padding: 5px 0;
}

.footer-list-inline > li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
}

.ant-layout-footer {
    position: fixed;
    width: 100%;
    z-index: 999;
    height: 30px !important;
    bottom: 0;
}