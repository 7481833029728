
.profile-container {
    padding: 16px;
    background: #ffffff;
    margin: auto;
}

.padding-16 {
    padding: 0 10px !important;
}

.change-password {
    margin-top: 20px !important;
}

.display-none {
    display: none;
}

.select-image {
    background: #1890FF;
    padding: 10px;
    color: white;
}

.width-100 {
    width: 100%;
}

.max-width-200 {
    max-width: 200px;
}

.width-200 {
    width: 200px;
}

.height-200 {
    height: 200px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.blank-div {
    height: 40px !important;
    width: 100%;
}

.text-align-center {
    text-align: center;
}

.ant-upload-select {
    margin-top: 7px !important;
}

.upload-profile-pic .ant-btn {
    border: 1px solid #1890FF;
}

.upload-profile-pic .ant-btn i {
    color: #1890FF;
}

.upload-profile-pic .ant-btn span {
    color: #1890FF;
}

.profile-display-inline {
    display: inline-block;
}

.clear-profile-image {
    position: absolute;
    right: 85px;
    top: 0;
}

.clear-profile-image button {
    background: black;
    color: white;
    border-radius: 50%;
}

.react-phone-number-input {
    padding: 4px !important;
}

.react-phone-number-input__icon {
    border: none !important;
    width: 2em !important;
    height: 2em !important;
}

.react-phone-number-input__icon svg {
    width: 2em;
    height: 2em;
}

.react-phone-number-input__phone {
    border: 1px solid rgb(217, 217, 217) !important;
    padding: 4px 11px !important;
    border-radius: 5px !important;
    height: 32px !important;
}

/* New CSS */

.profile-container .ant-scroll-number.ant-badge-count {
    background-color: black;
    color: white;
    font-weight: bolder;
    cursor: pointer;
}

.profile-container {
    margin: 30px 20px !important;
    border-radius: 5px !important;
    border-top: 2px #006DF0 solid !important;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, .35);
}

img.userProfile {
    border-radius: 5px;
    pointer-events: none;
}

.remove-profile-pic {
    margin-top: -160px !important;
}