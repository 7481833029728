/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

.application-logo-img-div {
    text-align: center;
}

.application-logo-img {
    width: 250px;
}

.signup-form-outer-div {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    margin: auto;
    position: absolute;
}

.signup-form-inner-div {
    padding: 20px;
    border: 1px solid #d9d9d9;
    margin: auto;
}

.signup-form-button {
    width: 100%;
}

.form-button {
    width: 100%;
}

/* New Css */

div.signup-form-outer-div {
    background-image: url('../../../../assets/background.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

div.signup-form-inner-div {
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 5px;
    width: 600px !important;
}

div.ant-row.ant-form-item {
    margin-bottom: 0 !important;
}

button.ant-btn.signup-form-button.ant-btn-primary {
    margin-top: 15px;
}

.signup-redirect-to-login {
    margin-top: 11px;
    vertical-align: middle;
}

div.disclaimer-container {
    font-size: 11px;
}

.disclaimer-title {
    text-align: center;
    margin-top: 20px;
}

.sign-up-form-inner-div .react-tel-input .form-control {
    width: 100% !important;
}

.height-35 {
    height: 35px;
}

.react-tel-input input[type=tel] {
    width: 100% !important;
}