div.subscription-container {
    margin: 20px auto;
    width: 100%;
    padding: 10px;
    text-align: center;
}

div.subscription-plan {
    margin: 10px;
    width: 300px;
    text-align: center;
    display: inline-block;
    background-color: #323550;
    vertical-align: top;
}

div.minimum-subscription-plan {
    height: 350px;
}

div.maximum-subscription-plan {
    height: 590px;
}

div.subscription-plan-details {
    margin: 7px;
    overflow-y: hidden;
}

div.subscription-plan:first-child {
    background-color: #10B2BF;
}

div.subscription-plan:nth-child(2) {
    background-color: #187BA7;
}

div.subscription-plan:nth-child(3) {
    background-color: #084F6D;
}

div.subscription-plan:nth-child(4) {
    background-color: #4F839A;
}

div.subscription-plan:nth-child(5) {
    background-color: #652BA5;
}

div.subscription-plan:nth-child(6) {
    background-color: #A03494;
}

div.subscription-plan:nth-child(7) {
    background-color: #C2108F;
}

.current-plan {
    background-color: #00b455 !important;
}

.cancel-subscription {
    background: #ffa500 !important;
    color: white !important;
    border: transparent !important;
    margin-top: 10px !important;
}

.color-white {
    color: white;
}

.plan-amount {
    font-size: 3em;
}

.align-top {
    vertical-align: top;
    line-height: 30px;
}

.color-gray {
    color: #ccc;
}

.pay-as-you-go-container {
    color: black;
    padding: 10px;
    background: white;
    margin: 20px;
}

.current-plan-info {
    color: #00b455 !important;
    font-size: 20px;
}

.current-plan-details {
    margin: 10px 0;
}

.current-plan-container {
    background: gray;
    padding: 10px;
    margin-top: 10px;
}

.current-plan-title {
    background: #ffa500 !important;
    color: white !important;
}