.fragment-title {
    font-weight: 700 !important;
    margin-right: 15px;
}

.add-fragment {
    border-radius: 50% !important;
    color: blue;
}

.custom-input-error {
    color: #f5222d;
}

.margin-top-7 {
    margin-top: 7px !important;
}

.edit-segment-container {
    margin-right: 10px;
}

.edit-segment {
    color: blue !important;
}