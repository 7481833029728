.padding-16 {
    padding: 0 10px !important;
}

.save-case {
    text-align: right;
    margin: 20px;
}

.add-fragment-btn-div {
    display: inline-block;
}

.ant-form-vertical {
    background: white;
    padding: 10px;
}

.location-fragment-mismatch {
    color: red;
}

.hide-display {
    display: none;
}

.case-creation-note {
    padding: 10px;
    margin: 10px;
}

.case-creation-container {
    border-radius: 5px !important;
    border-top: 2px #006DF0 solid !important;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, .35);
    background: white;
}