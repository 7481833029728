.dashboard-card {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2) !important;
}

.dashboard-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2) !important;
}

.dashboard-number {
    font-size: 2em;
    font-weight: 600;
}

.dashboard-analysis {
    margin-top: 20px;
    border-radius: 5px !important;
    border-top: 2px #006DF0 solid !important;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, .35);
    background: white;
}

/* New CSS */
.dashboard-card {
    /* border: none; */
    margin-bottom: 15px;
    position: relative;
    display: flex !important;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    /* background-color: #fff; */
    background-clip: initial;
    border: 1px solid #e3eaef;
    border-radius: .5rem !important;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, .35) !important;
    color: white !important;
}

.dashboard-card .dashboard-image {
    font-size: 2.5rem !important;
    opacity: .6 !important;
}

.dashboard-card .dashboard-number {
    font-size: 25px !important;
    font-weight: bold !important;
}

.bg-green {
    background-color: #00a65a !important;
}

.bg-blue {
    background-color: #0073b7 !important;
}

.bg-yellow {
    background-color: #f39c12 !important;
}

.bg-red {
    background-color: red !important;
}

.ant-card-body {
    padding: 10px 15px !important;
}

.dashboard-table-heading {
    font-weight: 600;
    font-size: 18px;
    padding: 10px;
}

div.dashboard-billing-container {
    padding: 10px;
}

div.billing-cell {
    border-top: 1px solid #777;
    padding: 10px;
}

div.billing-cell:nth-child(odd) {
    border-left: 1px solid #777;
}

div.billing-cell:nth-child(even) {
    border-right: 1px solid #777;
    border-left: 1px solid #777;
}

div.last-billing-cell {
    border-bottom: 1px solid #777;
}

.report-toggle-container {
    padding: 15px;
    text-align: center;
}


.active-user-report {
    display: inline-block;
}

.download-dashboard-report {
    margin-left: 10px;
}